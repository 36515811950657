import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc, getDoc, doc } from 'firebase/firestore'

// const apiKey = process.env.VUE_APP_API_KEY
const writeapiKey = process.env.VUE_APP_API_WRITE_KEY
// const readapiKey = process.env.VUE_APP_READ_API_KEY

// Initialize Firebase with your Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: "ao-loot-logger-eqms.firebaseapp.com",
  databaseURL: "https://ao-loot-logger-eqms-default-rtdb.firebaseio.com",
  projectId: "ao-loot-logger-eqms",
  storageBucket: "ao-loot-logger-eqms.appspot.com",
  messagingSenderId: "115738134094",
  appId: "1:115738134094:web:ddd8bce76941f889da251f",
  measurementId: "G-VYSDS48KHD"
}

const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app)

class Database {
  constructor() {
    this.valid = true // Assuming that Firestore initialization is successful
    console.log('Firestore init success')
  }

  async create(data) {
    if (process.env.NODE_ENV !== 'production' && !process.env.VUE_APP_ENABLE_WRITE_BIN) {
      console.log(`write bin is not enabled. env=${process.env.NODE_ENV} VUE_APP_ENABLE_WRITE_BIN=${process.env.VUE_APP_ENABLE_WRITE_BIN}`)
      return '60ca8b6f8ea8ec25bd0e8c50'
    }

    try {
      const docRef = await addDoc(collection(firestore, 'eqms-loot-logger'), {
        ...data,
        writeapiKey,
      })
      return docRef.id
    } catch (error) {
      console.error('Error creating data:', error)
      return null
    }
  }

  async read(id) {
    if (process.env.NODE_ENV !== 'production' && !process.env.VUE_APP_ENABLE_READ_BIN) {
      console.log(`read bin is not enabled. env=${process.env.NODE_ENV} VUE_APP_ENABLE_READ_BIN=${process.env.VUE_APP_ENABLE_READ_BIN}`)
      return {
        record: {
          lootLogs: [],
          chestLogs: [],
          showPlayers: {},
          hidePlayers: {},
        },
      }
    }

    try {
      const docRef = doc(firestore, 'eqms-loot-logger', id)
      const docSnapshot = await getDoc(docRef)

      if (docSnapshot.exists()) {
        const response = docSnapshot.data()
        console.log('Data read from Firestore:', response)
        return response
      } else {
        console.log('Data not found')
        return null
      }
    } catch (error) {
      console.error('Error reading data from Firestore:', error)
      throw error
    }
  }
}

const db = new Database()
export default db
