<template>
  <div title="Export missing items as CSV">
    <button @click="exportItems" :disabled="exporting || !hasFiles || !fileSaverSupport">
      <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
          ></path>
      </svg>
    </button>
    <div class="control-name">Export</div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'

import Items from '../services/items'

let saveAs = null

function template(history) {
  return `${history.lootedAt.toISOString()},${history.lootedBy},${Items.getNameFromId(history.itemId)},${
    history.itemId
  },${history.amount},${history.lootedFrom}`
}

export default {
  name: 'ExportButton',
  computed: {
    ...mapState(['exporting']),
    ...mapGetters(['filteredPlayers', 'hasFiles']),
    fileSaverSupport() {
      try {
        return !!new Blob()
      } catch (e) {
        return false
      }
    }
  },
  methods: {
    ...mapMutations(['setExporting']),
    async exportItems() {
      if (this.exporting) {
        return
      }

      this.setExporting(true)

      if (saveAs == null) {
        const fileSaver = await import('file-saver')

        saveAs = fileSaver.saveAs
      }

      let content = [`Pickup Time,Looted By,Item name,Item ID,Quantity,Looted From`]


      for (const playerName in this.filteredPlayers) {
        const player = this.filteredPlayers[playerName]

        for (const itemId in player.pickedUpItems) {
          const item = player.pickedUpItems[itemId]

          for (const history of item.history) {
            content.push(template(history))
          }
        }
      }

      const blob = new Blob([content.join('\n')], { type: 'text/plain;charset=utf-8' })

      saveAs(blob, `ao-loot-viewer-${new Date().getTime()}.csv`)

      this.setExporting(false)
    },
  }
}
</script>
