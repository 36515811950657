var render = function render(){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"item",class:{
    donated: _vm.type === 'donation',
    lost: _vm.type === 'lost',
    resolved: _vm.type === 'resolved',
    loaded: _vm.loaded,
    gold: _vm.isGold
  }},[_c('img',{attrs:{"src":_vm.url,"title":_vm.title,"loading":"lazy","alt":""},on:{"load":_vm.onLoad}}),_c('code',{staticClass:"amount"},[_vm._v(_vm._s(_vm.amount))])])
}
var staticRenderFns = []

export { render, staticRenderFns }